const initialState = {
  loading: false,
  name: "",
  totalSupply: 0,
  cost: 0,
  maxSupply: 0,
  maxMintAmount: 0,
  addressMintedBalance: 0,
  // balanceOf: 0,
  // whitelistedAddresses: 0,
  // addressMintedBalance: 0,
  // getRoundNumber: 0,
  // isWhitelisted: false,
  // viewBalance: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        name: action.payload.name,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        maxSupply: action.payload.maxSupply,
        maxMintAmount: action.payload.maxMintAmount,
        addressMintedBalance: action.payload.addressMintedBalance,
        // balanceOf: action.payload.balanceOf,
        // whitelistedAddresses: action.payload.whitelistedAddresses,
        // addressMintedBalance: action.payload.addressMintedBalance,
        // getRoundNumber: action.payload.getRoundNumber,
        // isWhitelisted: action.payload.isWhitelisted,
        // viewBalance: action.payload.viewBalance,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
