import React from 'react';
import {
  Nav,
  NavLink,
  NavMenu,
} from './NavbarElements';


const Navbar = () => {
    return (
      <>
        <Nav>
        <NavMenu>
        <NavLink to='/Home' activeStyle>
            Home
        </NavLink>
        <NavLink to='/Roadmap' activeStyle>
            Roadmap
        </NavLink>
        <NavLink to='/Info' activeStyle>
            Info
        </NavLink>
        </NavMenu>
        </Nav>
        </>
  );
};
  
export default Navbar;