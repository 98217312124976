import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import "./styles/theme.css";
import Roadmap from './Roadmap';
import Info from './Info';
import Mint from './Mint';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from "./Footer";

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
            <Navbar />
            <Switch>
                <Route exact path="/" component={Mint}/>
                <Route exact path="/Home" component={Mint}/>
                <Route exact path="/roadmap" component={Roadmap}/>
                <Route exact path="/Info" component={Info}/>
                <Route path="/404" component={Mint} />
                <Route path="**" component={Mint} />
            </Switch>
            <Footer />
        </BrowserRouter>
    </Provider>
,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
