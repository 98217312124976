import React from 'react';
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Halloween_banner from "./assets/images/halloween_banner.png";

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;
export const StyledOpensea = styled.img`
  width: 40%;

  @media (min-width: 767px) {
    width: 40%;

  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function Roadmap() {
    return (
        <>
        <s.Screen style={{ backgroundColor: "var(--black)" }}>
            <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
                <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
                <s.Container flex={1} jc={"top"} ai={"top"} style={{ backgroundColor: "#383838", padding: 24 }} >
                <s.TextTitle style={{ textAlign: "left", fontSize: 20, fontWeight: "bold" }} >
                            The Price!
                        </s.TextTitle>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                        A Naga Pixel costs 0.000000 ETH which is FREEEEEE! There is no more minting price for the collection "Naga Pixel" (except the gas fee)!
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextTitle style={{ textAlign: "left", fontSize: 20, fontWeight: "bold" }} >
                            The First Generation
                        </s.TextTitle>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                            There are many forms of the Naga in the lores for example human form, snake form, or even half-human half-snake form. Each form may have different compositions which in this generation adopted only one form and one composition, coiled snake, to be created as the NFTs to introduce the project "Naga Pixel". This form is exclusive only for the first generation!
                            <s.SpacerSmall />           
                        </s.TextDescription>
                        <s.SpacerSmall />   
                        <s.TextTitle style={{ textAlign: "left", fontSize: 20, fontWeight: "bold" }} >
                            What's next?
                        </s.TextTitle>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                            Naga Pixel comes with NGP token. The token will have utility as a whitelist for many upcoming projects from the team.
                            <s.SpacerSmall />
                    
                        </s.TextDescription>
                        <s.SpacerSmall />
                    </s.Container>
                </ResponsiveWrapper>
            </s.Container>
        </s.Screen>
        </>
    )
}

export default Roadmap