import React from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import logo from "./assets/images/logo.png";
import { Link } from 'react-router-dom';

export const StyledLogo = styled.img`
  width: 10%;
  height: 10%;
  @media (min-width: 767px) {
    width: 10%;
    height: 10%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledOpensea = styled.img`
  width: 100%;
  height: 100%;
  @media (min-width: 767px) {
    width: 100%;
    height: 100%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


function App() {

  return (
    <s.Screen style={{ backgroundColor: "var(--black)" }}>
      
      <Link to="/" style={{ textDecoration: 'none' }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
      <StyledLogo alt={"logo"} src={logo} />

      <s.TextTitle
          style={{ textAlign: "center", fontSize: 28, fontWeight: "bold" }}
        >
          NAGA PIXEL
        </s.TextTitle>
      
      </s.Container>
      </Link>

      
    </s.Screen>
    
  );
  
}

export default App;
