import React from 'react';
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i2 from "./assets/images/bg.png";
import tenpercent from "./assets/images/10%.png";

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;
export const StyledOpensea = styled.img`
  width: 20%;

  @media (min-width: 767px) {
    width: 30%;

  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 767px) {
    width: 512px;
    height: 512px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;


function Info() {
    return (
        <>
        <s.Screen style={{ backgroundColor: "var(--black)" }}>
            <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
                <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
                    <s.Container flex={1} jc={"center"} ai={"center"} style={{ backgroundColor: "#383838", padding: 24 }}>
                    <StyledImg alt={"Naga Pixel"} src={i2} />
                    </s.Container>
                    <s.Container flex={1} jc={"top"} ai={"top"} style={{ backgroundColor: "#383838", padding: 24 }} >
                        <s.TextTitle style={{ textAlign: "left", fontSize: 20, fontWeight: "bold" }} >
                            What is Naga Pixel?
                        </s.TextTitle>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                            Naga Pixel is the collection of 9,999 randomly pre-generated NFTs minted via a smart contract on the ETH Mainnet. The succesful minting reveals your NFT on OpenSea.io and LooksRare.org marketplaces. 
                            <s.SpacerSmall />
                            The idea of the pieces is based on the Naga, legendary serpents, which is generally found in Thai traditional arts.
                            For more information about the Naga, please check on the Medium.
                            <s.SpacerSmall />
                            Each Naga Pixel consists of different attributes such as colors, elements, locations, or even the types of the sky.
                            <s.SpacerSmall />
                            Of course, each attribute has its own rarity. you may try your luck if you want the attributes you are looking for!
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextTitle style={{ textAlign: "left", fontSize: 20, fontWeight: "bold" }} >
                            Why you need it?
                        </s.TextTitle>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                          The Naga Pixel was created to distribute the Thai-style art to those NFT-art lovers around the crypto world!.
                          <s.SpacerSmall />
                          More than the art itself, the holder of the NGP will have a whitelist to many more projects from the team.
                  
                            <s.SpacerSmall />
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextTitle style={{ textAlign: "left", fontSize: 20, fontWeight: "bold" }} >
                            The Team
                        </s.TextTitle>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                           Artist: Nipon_TK
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                           Frontend Developer: Nipon_TK
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                           Blockchain Developer: Nipon_TK
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                           Social Media Manager: Nipon_TK
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                           Narrative Designer: Nipon_TK
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "left" }}>
                           What else?: Nipon_TK
                        </s.TextDescription>
                        <s.SpacerSmall />
                    </s.Container>
                </ResponsiveWrapper>
            </s.Container>
        </s.Screen>
        </>
    )
}

export default Info