import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/animation.gif";
import opensea_logo from "./assets/images/opensea_logo.png";
import price from "./assets/images/price.png";
import sale_banner from "./assets/images/sale_banner.png";

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 767px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledLogo = styled.img`
  width: 30%;
  height: 30%;
  @media (min-width: 767px) {
    width: 10%;
    height: 10%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledOpensea = styled.img`
  width: 40%;

  @media (min-width: 767px) {
    width: 50%;

  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledPrice = styled.img`
  width: 20%;

  @media (min-width: 767px) {
    width: 30%;

  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [claimingNft, setClaimingNft] = useState(false);
  let round,roundSupply;
  let claimCost=0.02;
  let roundName; // "Public Sale 1", "Public Sale 2", "FCFS"
  let claimedNFT=0;
  
  const address_list=["0x67a8411ED325D2a6A7BaeaCa189B94a13D18DeAA","0x8F463815B5a984311F16c4eEE8cD74C8a0cbFC04", "0xCA35b7d915458EF540aDe6068dFe2F44E8fa733c","0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db","0x78731D3Ca6b7E34aC0F824c42a7cC18A495cabaB","0x617F2E2fD72FD9D5503197092aC168c91465E7f2", "0x17F6AD8Ef982297579C203069C1DbfFE4348c372", "0x14723A09ACff6D2A60DcdF7aA4AFf308FDDC160C", "0x5c6B0f7Bf3E7ce046039Bd8FABdfD3f9F5021678", "0x03C6FcED478cBbC9a4FAB34eF9f40767739D1Ff7", "0x0A098Eda01Ce92ff4A4CCb7A4fFFb5A43EBC70DC","0x9b7722E5bD673a8915bFa77033Cb98Ddb3E2114a"];

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your Naga Pixel...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(_amount)
      .send({
        gasLimit: 200000 * _amount,
        to: "0x6f967f34acc5d925cfffdfba6f35b66f7721c088",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.00 * _amount).toString(), "ether"),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
        
      })
      .then((receipt) => {
        setFeedback(
          "You now own a Naga Pixel! Visit Opensea.io to see it."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const checkWL = () => { //Work but with gas fee
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      let address = blockchain.account;
      blockchain.smartContract.methods
        .isWhitelisted(address)
        .send({
          from: blockchain.account
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
        })
        .then(
          function(response){ 
            console.log("checkWL: "+response);
          }
        );
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const isWhitelisted = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      for(let i=0;i<=address_list.length;i++){
          if(String(address_list[i]).toUpperCase() === String(blockchain.account).toUpperCase()){  
          return true;
        }
      }
    }
    return false;
  }

  const checkRound = () =>{
    if((data.cost/1000000000000000000)==0.005){
      roundName = "Presale";
      roundSupply = 503;
      claimCost = 0.005;
      round = 1;
    }else if((data.cost/1000000000000000000)==0.01){
      if(round == 2){
      roundSupply = 2003;
      claimCost = 0.01;
      }
      roundName = "Public Sale";
      roundSupply = 4003;
      claimCost = 0.01;
      round = 3;
    }else if((data.cost/1000000000000000000)==0.02){
      roundName = "FCFS";
      claimCost = 0.02;
      round = 4;
    }else{
      roundName = "N/A";
      claimCost = 1;
      round = 0;
    }
  }

  useEffect( () => {
    getData();
  }, [blockchain.account]);


  return (
      
    <s.Screen style={{ backgroundColor: "var(--black)" }}>
      <s.Container flex={2} ai={"center"} style={{ padding: 24 }}>
        <s.TextTitle style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }} >
          Mint your code-generated Naga Pixel
        </s.TextTitle>
          
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
        <s.Container flex={1} jc={"center"} ai={"center"}>
          <StyledImg alt={"example"} src={i1} />
          <s.SpacerMedium />
          <>
            {Number(data.totalSupply) != 0 ? (
              <>
              <s.TextTitle style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }} >
              {data.totalSupply}/9999
              </s.TextTitle>
              </>
            ):(
              <>
              <s.TextTitle style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }} >
                Upto 9999 NFTs!
              </s.TextTitle>
              </>
            )
            }
          </>
        </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"} style={{ backgroundColor: "--black", padding: 24 }} >
          {Number(data.totalSupply) >= 9896 ? (
            <>
                    <s.Container ai={"center"} jc={"center"}>
                    <s.TextTitle style={{ textAlign: "center" }}>
                      SOLD OUT!
                    </s.TextTitle>
                    <s.TextDescription style={{ textAlign: "center" }}>
                            You can still find the Naga Pixel on{" "}
                            <s.SpacerMedium />
                        <a target={"_blank"} href={"https://opensea.io/collection/naga-pixel"} >
                        <StyledOpensea alt={"opensea_logo"} src={opensea_logo} />
                        </a>
                    </s.TextDescription>
                    </s.Container>
                    </>
          ):(
            <>
              <s.TextSubTitle style={{ textAlign: "center" }}>
                            Welcome {blockchain.account}
              </s.TextSubTitle>
              <s.SpacerSmall />
                              <s.SpacerSmall />
                    <s.TextTitle style={{ textAlign: "center" }}>
                            1 Naga Pixel costs 0.00 ETH.
                            <s.SpacerSmall />
                            Yes! It's free!!!
                        </s.TextTitle>
               
                    <s.TextDescription style={{ textAlign: "center" }}>
                        (Excluding gas fee)
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center" }}>
                        You can mint only 1 NFT per address.
                    </s.TextDescription>
                      <s.SpacerXSmall />
                    <s.TextDescription style={{ textAlign: "center"}}>
                      
                        {feedback}
                        
                    <s.SpacerSmall />
                    </s.TextDescription>
                    <s.SpacerMedium />
                  {blockchain.account === "" || blockchain.smartContract === null ? ( //Check Metamask connection
                    <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center" }}>
                        Connect to the Ethereum Main network
                    </s.TextDescription>
                    <s.SpacerSmall />
                        <StyledButton
                            onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                            }}
                        >
                            CONNECT
                        </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                        <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                        <p style={{color:"red"}}>
                            {blockchain.errorMsg}
                        </p>
                        </s.TextDescription>
                        </>
                    ) : null}
                    </s.Container>
                ):(
                  // data.maxSupply
                  Number(data.totalSupply) >= 9999 ? (
                    <>
                    <s.Container ai={"center"} jc={"center"}>
                    <s.TextTitle style={{ textAlign: "center" }}>
                      SOLD OUT!
                    </s.TextTitle>
                    <s.TextDescription style={{ textAlign: "center" }}>
                            You can still find the Naga Pixel on{" "}
                            <s.SpacerMedium />
                        <a target={"_blank"} href={"https://opensea.io/collection/naga-pixel"} >
                        <StyledOpensea alt={"opensea_logo"} src={opensea_logo} />
                        </a>
                    </s.TextDescription>
                    </s.Container>
                    </>
                  ):(
                      Number(data.addressMintedBalance) > 0 ?(
                        <>
                      <s.TextTitle>
                        You have already minted {data.addressMintedBalance}!
                      </s.TextTitle>
                        <s.TextTitle style={{ textAlign: "center" }}>
                        Thank you for your support.
                      </s.TextTitle>
                      <s.SpacerMedium />
                      <s.TextDescription style={{ textAlign: "center" }}>
                      
                              You can find your Naga Pixel on{" "}
                              <s.SpacerSmall />
                          <a target={"_blank"} href={"https://opensea.io/collection/naga-pixel"} >
                          <StyledOpensea alt={"opensea_logo"} src={opensea_logo} />
                          </a>
                        </s.TextDescription>
                      </>
                      ):(
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>

                            <>
                                <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTs(1);
                                            getData();
                                        }
                                    }
                                >
                                    {claimingNft ? "BUSY" : "Mint 1"}
                                </StyledButton>
                            </>

                          </s.Container>
                      )
                             
                  )
                )
              
              }
                          <s.SpacerLarge />
                          <s.TextDescription style={{ textAlign: "center", fontSize: 9 }}>
                            The gas limit was set to 200000 per NFT. 
                            <s.SpacerXSmall />
                            It is recommended that you don't lower the gas limit for the successful minting.
                          </s.TextDescription>
          </>
          )
        }
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
      </s.Screen>
  );
  
}

export default Mint;