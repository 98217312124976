import React from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import MediumLogo from "./assets/images/Medium_logo.png";
import twitterLogo from "./assets/images/twitter_logo.png";
import openseaLogo from "./assets/images/opensea-logo.png";
import etherscanLogo from "./assets/images/etherscan_logo.png";

export const StyledLogo = styled.img`
  width: 15%;
  height: 15%;
  @media (min-width: 767px) {
    width: 15%;
    height: 15%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;


function Footer() {

    return (
      <s.Screen style={{ backgroundColor: "var(--black)" }}>
        <s.Container ai={"center"} style={{ padding: 15 }}>
          <p>
            <a href="https://nagapixelnft.medium.com/">
              <StyledLogo alt={"medium_logo"} src={MediumLogo} /></a>
              {"      "}
            <a href="https://twitter.com/Nipon_TK">
              <StyledLogo alt={"twitter_logo"} src={twitterLogo} /></a> 
              {"      "}
            <a href="https://opensea.io/collection/naga-pixel">
              <StyledLogo alt={"opensea_logo"} src={openseaLogo} /></a>  
              {"      "}
              <a href="https://etherscan.io/token/0x6f967f34acc5d925cfffdfba6f35b66f7721c088">
              <StyledLogo alt={"etherscan_logo"} src={etherscanLogo} /></a>
              <s.SpacerXSmall />
              <s.TextDescription ai={"center"}>
              &copy; 2021 NagaPixel
                </s.TextDescription>
          </p>

        </s.Container>
  
      </s.Screen>
      
    );
    
  }
  
  export default Footer;